'use client';

import * as React from 'react';
import { OverlayKey } from '@/lib/enums/overlayKey.enum';
import styles from '../Overlay/styles.module.scss';

export type SetDifference<A, B> = A extends B ? never : A;
type Subtract<T extends T1, T1 extends object> = Pick<T, SetComplement<keyof T, keyof T1>>;
type SetComplement<A, A1 extends A> = SetDifference<A, A1>;

export interface OverlaysState {
  scrollbarWidth: number;
  openOverlays: string[];
  savedScrollPosition: number;
  meta: {
    [key in OverlayKey]?: object;
  };
}

export interface IWithOverlays extends OverlaysState {
  overlaysActions: React.Dispatch<OverlaysDispatchAction>;
}

export interface OverlaysDispatchAction {
  action: 'open' | 'close';
  name: string;
  asNew?: boolean;
  instant?: boolean;
  noScrollRestore?: boolean;
  meta?: object | null;
}

const initialState: OverlaysState = {
  scrollbarWidth: 0,
  openOverlays: [],
  meta: {},
  savedScrollPosition: 0,
};

const doc = typeof document !== 'undefined' ? document.documentElement : undefined;

const overlaysReducer = (state: OverlaysState, payload: OverlaysDispatchAction): OverlaysState => {
  const closeOverlayCleanup = (instant?: boolean, noScrollRestore?: boolean) => {
    if (doc) {
      doc.style.height = '';
      const root = document.getElementById('__next');
      if (root) {
        root.style.height = '';
        root.style.minHeight = '';
      }
      doc.classList.remove(styles['overlayopen']);
      if (!noScrollRestore && state.savedScrollPosition > 0) {
        document.body.scrollTop = state.savedScrollPosition;
        doc.scrollTop = state.savedScrollPosition;
        window.requestAnimationFrame(() => {
          document.body.scrollTop = state.savedScrollPosition;
          doc.scrollTop = state.savedScrollPosition;
        });
      }
    }
  };

  const handleOpen = (): OverlaysState => {
    if (state.openOverlays.includes(payload.name)) {
      return {
        ...state,
      };
    } else {
      const scroller = document.getElementById('__next');
      if (doc) {
        const pos = doc.scrollTop || document.body.scrollTop;

        if (state.openOverlays.length === 0) {
          // Scroll to top
          window.scrollTo(0, 0);

          // Clip window to size
          doc.classList.add(styles['overlayopen']);

          window.requestAnimationFrame(() => {
            if (scroller) {
              scroller.scrollTop = pos;
            }
          });
        }
        let arr: string[] = [];
        if (payload.asNew) {
          arr = [...state.openOverlays, payload.name];
        } else {
          arr = [payload.name];
        }

        let newScrollWidth = window.innerWidth - doc.clientWidth;
        if (state.scrollbarWidth && state.scrollbarWidth > newScrollWidth) {
          newScrollWidth = state.scrollbarWidth;
        }

        return {
          ...state,
          scrollbarWidth: newScrollWidth,
          savedScrollPosition: pos,
          openOverlays: arr,
          meta: {
            ...state.meta,
            [payload.name]: payload.meta,
          },
        };
      }
    }

    return {
      ...state,
    };
  };

  const handleClose = (): OverlaysState => {
    let arr = [...state.openOverlays];
    if (doc) {
      if (arr.length > 0) {
        if (payload.name === 'all') {
          arr = [];
        } else if (payload.name) {
          arr = arr.filter((i) => i !== payload.name);
        } else {
          arr.splice(-1, 1);
        }
        if (doc && arr.length === 0) {
          closeOverlayCleanup(false, payload.noScrollRestore);
        }
      } else if (payload.name === 'all') {
        closeOverlayCleanup(payload.instant, payload.noScrollRestore);
      }
    }

    let newScrollbarWidth = state.scrollbarWidth;

    if (doc && !doc.classList.contains(styles['overlayopen'])) {
      newScrollbarWidth = 0;
    }

    return {
      ...state,
      scrollbarWidth: newScrollbarWidth,
      openOverlays: arr,
    };
  };

  switch (payload.action) {
    case 'open':
      return handleOpen();
    case 'close':
      return handleClose();
    default:
      return {
        ...initialState,
      };
  }
};

const Overlays = React.createContext<IWithOverlays>({
  ...initialState,
  overlaysActions: (action) => {
    overlaysReducer(initialState, action);
  },
});

const OverlaysProvider: React.FunctionComponent<{
  /** Children to render */
  children?: React.ReactNode;
}> = (props) => {
  const [state, dispatch] = React.useReducer(overlaysReducer, initialState);
  return (
    <Overlays.Provider
      value={{
        ...state,
        overlaysActions: dispatch,
      }}
    >
      {props.children}
    </Overlays.Provider>
  );
};

const OverlaysConsumer = Overlays.Consumer;

const withOverlays = <P extends IWithOverlays, ComponentProps = Subtract<P, IWithOverlays>>(
  Component: React.Component<P> | React.ComponentClass<P> | React.FunctionComponent<P>,
) => {
  return class WithOverlays extends React.Component<ComponentProps> {
    static displayName = 'withOverlays';

    render() {
      return (
        <OverlaysConsumer>
          {(value) => {
            return (
              // eslint-disable-next-line
              // @ts-ignore
              <Component
                {...this.props}
                scrollbarWidth={value.scrollbarWidth}
                openOverlays={value.openOverlays}
                savedScrollPosition={value.savedScrollPosition}
                overlaysActions={value.overlaysActions}
              />
            );
          }}
        </OverlaysConsumer>
      );
    }
  };
};

export { Overlays, OverlaysProvider, OverlaysConsumer, withOverlays };
