'use client';

import * as React from 'react';
import { useRouter } from 'next/navigation';
import { Overlays } from '@/components/ui/Overlays';
import { handleOverlayClosing } from '@/components/helpers/overlays';
import { useHash } from '@/components/hooks/useHash';
import { OverlayKey } from '@/lib/enums/overlayKey.enum';

interface IOverlayOptions<T> {
  asNew?: boolean;
  closeRestOverlays?: boolean;
  meta?: T | null;
}

export const useOverlay = <T extends object | null = null>(overlayName: string) => {
  const router = useRouter();
  const [hash, setHash] = useHash();

  const { overlaysActions, openOverlays, meta } = React.useContext(Overlays);

  const openOverlay = React.useCallback(
    (
      triggerEvent?: React.SyntheticEvent,
      options: IOverlayOptions<T> = {
        asNew: true,
        closeRestOverlays: false,
      },
    ) => {
      if (!overlayName) {
        return;
      }

      if (triggerEvent?.preventDefault) {
        triggerEvent.preventDefault();
      }
      if (options.closeRestOverlays) {
        overlaysActions({
          action: 'close',
          name: 'all',
        });
      }

      setHash(overlayName);

      if (openOverlays.includes(overlayName)) {
        closeOverlay();
      } else {
        overlaysActions({
          action: 'open',
          name: overlayName,
          asNew: options.asNew,
          meta: options.meta,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openOverlays, overlaysActions, router, overlayName],
  );

  const closeOverlay = React.useCallback(
    (triggerEvent?: React.SyntheticEvent) => {
      if (!overlayName) {
        return;
      }

      if (triggerEvent?.preventDefault) {
        triggerEvent.preventDefault();
      }

      if (hash !== overlayName) {
        overlaysActions({
          action: 'close',
          name: overlayName,
        });
        return;
      }

      if (openOverlays.length >= 1) {
        router.back();
      }
    },
    [overlaysActions, router, hash, overlayName, openOverlays],
  );

  const isOverlayOpen = React.useMemo(() => openOverlays.includes(overlayName), [openOverlays, overlayName]);

  const zIndexOfOverlay = React.useMemo(() => openOverlays.findIndex((o) => o === overlayName), [openOverlays, overlayName]);

  React.useEffect(() => {
    // Open on mount if hash is present
    if (hash === overlayName && !isOverlayOpen) {
      overlaysActions({
        action: 'open',
        name: overlayName,
        asNew: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    openOverlay,
    closeOverlay,
    isOverlayOpen,
    zIndexOfOverlay,
    overlayMeta: meta[overlayName as OverlayKey] as T,
  };
};

export const useOverlayClosing = () => {
  const [hash] = useHash();
  const prevHash = React.useRef(hash);
  const { overlaysActions, openOverlays } = React.useContext(Overlays);

  // Watch for hash changes

  React.useEffect(() => {
    handleOverlayClosing(hash, prevHash.current, openOverlays, overlaysActions);

    return () => {
      prevHash.current = hash;
    };
  }, [hash, openOverlays, overlaysActions]);

  // Close overlay when unmounting

  React.useEffect(() => {
    return () => {
      overlaysActions({
        action: 'close',
        name: 'all',
      });
    };
  }, [overlaysActions]);
};
